.lc-page-wrapper {
  padding-top: 20px;
}

.lc-page-wrapper ul {
  flex-direction: row;
  display: flex;
  padding-inline-start: 0em;
}

.lc-page-item {
  display: inline-block;
  margin-left: 0;
  margin-right: 0;
  color: #007bff;
}

.lc-page-break {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  color: #007bff;
}

.lc-page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  border: 1px solid #dee2e6;
}

.lc-page-active {
  z-index: 1;
  color: #fff;
  background-color: #007bff !important;
  border-color: #007bff;
}

.lc-page-active a {
  color: #fff;
}

.lc-page-disabled a {
  color: #dee2e6
}
